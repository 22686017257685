<template>
  <div class="row">
    <div class="col-12">
      <div class="card mb-4">
        <div class="card-body pt-4 p-3">
          <div class="input-group input-group-outline mb-3">
            <input
              type="text"
              v-model="nip"
              class="form-control"
              placeholder="Isikan NIP Pegawai"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
            />
            <button
              @click="verify"
              :disabled="checking"
              class="btn btn-outline-success"
              style="margin: 0px"
              type="button"
            >
              <span v-if="checking" class="material-icons spin-animate">
                hourglass_bottom
              </span>
              Cek
            </button>
          </div>
          <v-select :options="listPegawai" @input="selectedPegawai"></v-select>
        </div>
      </div>
    </div>
    <div v-if="model.nip" class="col-md-12">
      <div class="card h-100 mb-4">
        <div class="card-header pb-0 px-3">
          <div class="row">
            <div class="col-md-6">
              <h6 class="mb-0">Perhitungan Masa Kerja</h6>
            </div>
            <div class="col-md-6 text-end"></div>
          </div>
        </div>
        <div class="card-body p-3 row">
          <div class="col-6">
            <ul class="list-group ms-4">
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">NIP:</strong>
                {{ model.nip }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Nama:</strong>
                {{ model.nama }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Golongan:</strong>
                {{ model.golongan }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">TMT:</strong>
                <div class="row">
                  <div class="col-3">
                    <div class="input-group input-group-outline">
                      <input
                        class="form-control bg-gray-300"
                        readonly
                        value="01"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="input-group input-group-outline">
                      <select
                        @change="setTmt"
                        class="form-select form-control"
                        v-model="month"
                      >
                        <option
                          v-for="(m, keyValue) in months"
                          :key="keyValue"
                          :value="keyValue"
                        >
                          {{ m }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="input-group input-group-outline">
                      <input
                        @change="setTmt"
                        class="form-control"
                        v-model="year"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="list-group ms-4">
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Tanggal Lahir:</strong>
                {{ model.tanggal_lahir }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">TMT Pensiun (BUP 58):</strong>
                {{ model.pensiun }}
              </li>
              <li class="list-group-item border-0 ps-0 text-sm">
                <strong class="text-dark">Masa Kerja Pensiun:</strong>
                {{ model.masa_kerja_pensiun }}
              </li>
              <li>
                <button
                  @click="syncPangkat(model.nip)"
                  class="btn btn-primary"
                  :disabled="isSync"
                >
                  <i v-if="isSync" class="fa fa-spin fa-spinner"></i>
                  Sinkron History Kepangkatan
                </button>
              </li>
            </ul>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <button
                  class="nav-link"
                  :class="[currentTab == 'pangkat' ? 'active' : '']"
                  @click="setTab('pangkat')"
                >
                  Berdasarkan Pangkat
                </button>
              </li>
              <li class="nav-item">
                <button
                  class="nav-link"
                  :class="[currentTab == 'kgb' ? 'active' : '']"
                  @click="setTab('kgb')"
                >
                  Berdasarkan KGB
                </button>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane col active" v-if="currentTab == 'pangkat'">
                <div class="col-12">
                  <table class="table">
                    <thead class="bg-gradient-success text-light">
                      <tr>
                        <th>No</th>
                        <th>Pangkat/Golongan</th>
                        <th>TMT</th>
                        <th>Masa Kerja Saat SK</th>
                        <th>Masa Kerja Saat Ini (Golongan)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in model.masa_kerja.pangkat"
                        :key="index"
                        class="p2"
                      >
                        <td>{{ index + 1 }}</td>
                        <td class="text-center">
                          {{ item.golongan }} <br />
                          ({{ jenisSk(item.tipe) }})
                        </td>
                        <td>{{ formatDate(item.tmt) }}</td>
                        <td>
                          {{ item.masa_kerja_sk }}
                          <a @click="showModalEdit(item)" href="#"
                            ><i class="fa fa-edit"></i
                          > Edit</a>
                        </td>
                        <td>{{ item.masa_kerja }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane col active" v-if="currentTab == 'kgb'">
                <div class="col-12">
                  <table class="table">
                    <thead class="bg-gradient-success text-light">
                      <tr>
                        <th>No</th>
                        <th>Pangkat/Golongan</th>
                        <th>TMT</th>
                        <th>Masa Kerja Saat SK</th>
                        <th>Masa Kerja Saat Ini (Golongan)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in model.masa_kerja.kgb"
                        :key="index"
                        class="p2"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.golongan }}</td>
                        <td>{{ formatDate(item.tmt) }}</td>
                        <td>{{ item.masa_kerja_sk }}</td>
                        <td>{{ item.masa_kerja }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade show"
      id="modal-edit"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Edit Masa Kerja Pada SK
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label class="form-label">Masa Kerja Bulan</label>
                <div class="input-group input-group-dynamic mb-4">
                  <input
                    type="number"
                    v-model="editPangkat.masa_kerja_golongan"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Masa Kerja</label>
                <div class="input-group input-group-dynamic mb-4">
                  <input
                    type="text"
                    :value="editMasaKerja"
                    readonly
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Jenis SK</label>
                <div class="input-group input-group-dynamic mb-4">
                  <label class="me-2">
                    <input type="radio" v-model="editPangkat.tipe" value="1" />
                    SK KGB
                  </label>
                  <label class="me-2">
                    <input type="radio" v-model="editPangkat.tipe" value="2" />
                    SK Naik Pangkat
                  </label>
                  <label class="me-2">
                    <input type="radio" v-model="editPangkat.tipe" value="3" />
                    SK CPNS
                  </label>
                  <label class="me-2">
                    <input type="radio" v-model="editPangkat.tipe" value="4" />
                    SK PNS
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              :disabled="this.editPangkat.saving"
              type="button"
              class="btn btn-primary"
              @click="savePangkat"
            >
              <i
                v-if="this.editPangkat.saving"
                class="fa fa-spin fa-spinner"
              ></i>
              Save
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
const bootstrap = require("bootstrap");

moment.locale("id");

export default {
  name: "VerifyKGBPage",
  props: {
    msg: String,
  },
  components: {
    vSelect,
  },
  data: () => {
    return {
      nip: "",
      checking: false,
      model: {},
      modalEdit: null,
      editPangkat: {
        id: null,
        masa_kerja_golongan: 0,
        saving: false,
        tipe: null,
      },
      isSync: false,
      currentTab: "pangkat",
      listPegawai: [],
      tmt: moment().format("yyyy-MM-01"),
      month: moment().format("M"),
      year: moment().format("yyyy"),
    };
  },
  computed: {
    months() {
      return moment.months();
    },
    editMasaKerja() {
      return `${Math.floor(this.editPangkat.masa_kerja_golongan / 12)} Tahun ${
        this.editPangkat.masa_kerja_golongan % 12
      } Bulan`;
    },
  },
  mounted() {
    this.fetchPegawai();
    this.modalEdit = new bootstrap.Modal(
      document.getElementById("modal-edit"),
      {}
    );
  },
  methods: {
    jenisSk(tipe) {
      if (tipe == "1") return "SK KGB";
      if (tipe == "2") return "SK Naik Pangkat";
      if (tipe == "3") return "SK CPNS";
      if (tipe == "4") return "SK PNS";
    },
    showModalEdit(row) {
      console.log(row);
      this.editPangkat = {
        id: row.id,
        masa_kerja_golongan: row.masa_kerja_bulan_sk,
        saving: false,
        tipe: row.tipe,
      };
      this.modalEdit.show();
    },
    savePangkat() {
      this.editPangkat.saving = true;
      axios
        .put(`/api/masakerja/${this.editPangkat.id}`, this.editPangkat)
        .then((res) => {
          if (res.status) {
            this.modalEdit.hide();
            this.verify();
          } else {
            Swal.fire("Informasi", res.data.message, "warning");
          }
        })
        .catch((er) => {
          console.log(er);
          Swal.fire("Informasi", er.message, "error");
        })
        .finally(() => {
          this.editPangkat.saving = false;
        });
    },
    setTab(tab) {
      this.currentTab = tab;
    },
    setTmt() {
      let month = String(Number(this.month) + 1).padStart(2, 0);
      this.tmt = `${this.year}-${month}-01`;
      this.verify();
    },
    selectedPegawai(item) {
      this.nip = item.nip;
      this.verify();
    },
    syncPangkat(nip) {
      this.isSync = true;
      axios
        .get(`/api/sync/${nip}/kepangkatan`)
        .then(() => {
          this.verify();
        })
        .catch((err) => {
          alert(err.message);
        })
        .finally(() => {
          this.isSync = false;
        });
    },
    fetchPegawai() {
      axios
        .get(`/api/pegawai`)
        .then((res) => {
          if (res.data.data) {
            this.listPegawai = res.data.data;
          } else {
            if (res.data.message) {
              Swal.fire("Informasi", res.data.message, "warning");
            }
            this.model = {};
          }
        })
        .finally(() => {
          this.checking = false;
        });
    },
    formatDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    verify() {
      this.checking = true;
      axios
        .get(`/api/masakerja/${this.nip}/${this.tmt}`)
        .then((res) => {
          if (res.data.data) {
            this.model = res.data.data;
          } else {
            if (res.data.message) {
              Swal.fire("Informasi", res.data.message, "warning");
            }
            this.model = {};
          }
        })
        .finally(() => {
          this.checking = false;
        });
    },
  },
};
</script>
<style>
.mx-input:disabled {
  color: black !important;
}
</style>
